export default {
  name : "SplashScreen",
  props: {
  },
  data: () => ({
    progressPercent: null
  }),
  mounted() {
    this.updateProgress()
  },
  methods: {
    updateProgress() {
      this.progressPercent = 0
      const updateInterval = setInterval(() => {
        if (this.progressPercent < 80) {
          this.progressPercent += 4
        } else if (this.progressPercent < 98) {
          this.progressPercent += 1
        } else {
          clearInterval(updateInterval)
        }
      }, this.progressPercent < 80 ? 100 : 1000)
    }
  }
}