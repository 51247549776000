/**
 * @file It contains all the vuex configurations module state variables.
 */
export default {
  loadingConfigurations: undefined,
  configurations       : new Array(),
  updatingConfiguration: {
    value: false
  },
  configurationUpdated: {
    value: false
  },
  configurationUpdateError: {
    value: false
  },
  splashScreen: false

}