/**
 * @file It contains vuex configurations module mutations to mutate the state variables.
 */
export default {
  setLoadingConfigurations: (state, loadingConfigurations) => {
    state.loadingConfigurations = loadingConfigurations
  },
  setConfigurations: (state, configurations) => {
    state.configurations = configurations
  },
  updateConfigurations: (state, configurations) => {
    for (const configuration of configurations) {
      const index = state.configurations.findIndex(stateConfiguration => stateConfiguration?.id === configuration.id)
      if (index >= 0) {
        state.configurations.splice(index, 1, { ...state.configurations[index], ...configuration })
      } else {
        state.configurations.push(configuration)
      }
    }
  },
  setUpdatingConfiguration: (state, properties) => {
    for (const property of properties) {
      state.updatingConfiguration[property] = true
    }
  },
  resetUpdatingConfiguration: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.updatingConfiguration[property] = false
      }
    } else {
      Object.keys(state.updatingConfiguration).forEach(property => state.updatingConfiguration[property] = false)
    }
  },
  setConfigurationUpdated: (state, properties) => {
    for (const property of properties) {
      state.configurationUpdated[property] = true
      setTimeout(() => {
        state.configurationUpdated[property] = false
      }, 5000)
    }
  },
  resetConfigurationUpdated: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.configurationUpdated[property] = false
      }
    } else {
      Object.keys(state.configurationUpdated).forEach(property => state.configurationUpdated[property] = false)
    }
  },
  setConfigurationUpdateError: (state, { properties, error }) => {
    for (const property of properties) {
      state.configurationUpdateError[property] = error
    }
  },
  resetConfigurationUpdateError: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.configurationUpdateError[property] = undefined
      }
    } else {
      Object.keys(state.configurationUpdateError).forEach(property =>
        state.configurationUpdateError[property] = undefined)
    }
  },
  setSplashScreen: (state, splashScreen) => {
    state.splashScreen = splashScreen
  }
}